<h1 mat-dialog-title>Notes Confirmation</h1>
<div mat-dialog-content>
    <mat-form-field class="mt-2 small-x-gap-lg" style="width: 100%" appearance="fill">
        <textarea matInput placeholder="" [(ngModel)]="tenantNotes" rows="1" (input)="userInput('repairNotes')"
            maxLength="{{ max_char_repairNotes }}" rows="5"></textarea>
        <mat-hint>{{ "Tenant Notes" }}</mat-hint>
    </mat-form-field>
    <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number reached.</div>
</div>

<div mat-dialog-content>
    <mat-form-field class="mt-2 small-x-gap-lg" style="width: 100%" appearance="fill">
        <!-- <mat-label>{{ "Property Manager Notes" | translate }} </mat-label> -->
        <textarea matInput placeholder="" [(ngModel)]="propertyManagerNotes" rows="1" (input)="userInput('repairNotes')"
            maxLength="{{ max_char_repairNotes }}" rows="5"></textarea>
        <mat-hint>{{ "Property Manager Notes" }}</mat-hint>
    </mat-form-field>
    <div *ngIf="reached_max_char_propertyManagerNotes" class="max-char-warning">Maximum number reached.</div>
</div>

<div mat-dialog-actions class="d-flex justify-content-end">
    <button mat-flat-button class="message-ok-button button-default-thin text-uppercase"
        (click)="onNoClick()">Cancel</button>
    <button mat-flat-button class="ml-2 message-ok-button button-default-thin text-uppercase button-ok"
        [mat-dialog-close]="delete" (click)="onOk()" cdkFocusInitial>Ok</button>
</div>