import { Component, OnInit, Input, ViewChild, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { Observable, forkJoin, lastValueFrom } from 'rxjs';
import { AppServiceService } from '../services/app-service.service';
import { AuthService } from '../services/auth.service';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Clipboard } from '@angular/cdk/clipboard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-work-order-files-viewer',
  standalone: true,
  imports: [
    CommonModule,
    HttpClientModule,
    SlickCarouselModule,
    FontAwesomeModule,
  ],
  templateUrl: './work-order-files-viewer.component.html',
  styleUrl: './work-order-files-viewer.component.scss'
})
export class WorkOrderFilesViewerComponent {

  faCopy = faCopy;


  @ViewChild('slickCarousel') slickCarousel: SlickCarouselModule = new SlickCarouselModule;

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    // .set('Access-Control-Allow-Origin', '*')
    // .set('Access-Control-Allow-Methods', 'OPTIONS,POST')
    // .set("Access-Control-Allow-Headers", 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token')
    // .set('Authorization', `Bearer ${environment.mlsBearToken}`)
    .set('Content-Type', `application/json`);

  filePathsSignedURLPerWO: any;
  files: any = [];
  types: any = [];
  session: any;

  currentIndex = 0;

  slideConfig = {
    'slidesToShow': 1,
    'slidesToScroll': 1,
    'dots': false,
    'infinite': false,
    'autoplay': false,
    // responsive: [
    //   {
    //     'breakpoint': 1024,
    //     'settings': {
    //       'slidesToShow': 1,
    //       'slidesToScroll': 1,
    //       'infinite': true,
    //       'dots': false
    //     }
    //   }, {
    //     'breakpoint': 991,
    //     'settings': {
    //       'slidesToShow': 1,
    //       'slidesToScroll': 1,
    //       'infinite': true,
    //       'dots': false
    //     }
    //   }, {
    //     'breakpoint': 480,
    //     'settings': {
    //       'slidesToShow': 1,
    //       'slidesToScroll': 1,
    //       'infinite': true,
    //       'dots': false
    //     }
    //   }
    // ],
  };

  constructor(
    public authService: AuthService,
    private httpClient: HttpClient,
    public appService: AppServiceService,
    public dialogRef: MatDialogRef<WorkOrderFilesViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private clipboard: Clipboard,
  ) {
    this.filePathsSignedURLPerWO = data.filePathsSignedURLPerWO;
    this.types = data.filePaths.map((path: any) => path.type);

    data.filePathsSignedURLPerWO.forEach((file: any, i: number) => {

      let publicUrl = null;
      if (file.publicUrl) {
        publicUrl = file.publicUrl
        console.log(publicUrl)
      }

      this.files.push({
        'image': file.uploadUrl,
        'index:': i,
        'type': this.types[i],
        'name': this.getFileNameFromPath(file.objectKey),
        'publicUrl': publicUrl,
      })
    });
  }

  async ngOnInit() {
    this.session = await this.authService.getCurrentSession();

    // this.getImages();

  }

  copyText(file) {
    this.clipboard.copy(file.publicUrl);
  }

  getFileNameFromPath(filePath: string) {
    let fileNameSplit = filePath.split('/');
    if (fileNameSplit.length > 0) {
      return fileNameSplit[fileNameSplit.length - 1];
    } else {
      return '';
    }
  }

  isVideo(item: any) {
    if (item && item.toLowerCase().includes('video')) {
      return true;
    } else {
      return false;
    }
  }


  getImageFromSignedUrl(url: string): Observable<any> {
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    return this.httpClient.get(url, { headers: this.httpHeaders, observe: 'response' });
  }

  getImages() {
    let allSignedUrlsReqs: Observable<any>[] = [];

    this.filePathsSignedURLPerWO.forEach((path: string) => {
      allSignedUrlsReqs.push(this.getImageFromSignedUrl(path))
    })

    forkJoin(allSignedUrlsReqs).subscribe({
      next: (responses) => {
        responses.forEach((r: any, i: number) => {
          this.files.push({
            image: r,
            index: i,
          })
        })
      },
      error: (error) => {
        console.error('Error in getting images from signed urls:', error);
      }
    });
  }

  onAfterChangeSlide(event: any): void {
    this.currentIndex = event.currentSlide;
  }
}
