<div class="d-flex justify-content-center">
    <mat-card class="example-card">
        <mat-card-content style="padding: 26px;">
            <h2 class="example-h2">Processing... this could take a minute or two. We appreciate your
                patience.</h2>

            <section class="example-section">
                <mat-progress-bar class="example-margin" mode="determinate" [value]="progress">
                </mat-progress-bar>
            </section>
        </mat-card-content>
    </mat-card>

</div>