import { Component, Input, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarMode, MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSliderModule } from '@angular/material/slider';
import { FormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { MatCardModule } from '@angular/material/card';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-progress-bar',
  standalone: true,
  imports: [
    MatCardModule, MatRadioModule, FormsModule, MatSliderModule, MatProgressBarModule,
    CommonModule
  ],
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent {

  progress: number;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    this.progress = data.progress;
  }
}
