import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import {
  FormsModule,
} from '@angular/forms';
import { faBan, faCheck } from '@fortawesome/free-solid-svg-icons';
import { ENUMS } from '../../models/constants';

@Component({
  selector: 'app-notes-dialog',
  standalone: true,
  imports: [
    CommonModule,
    MatIconModule,
    MatInputModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
  ],
  templateUrl: './notes-dialog.component.html',
  styleUrl: './notes-dialog.component.scss'
})
export class NotesDialogComponent {

  tenantNotes: any;
  propertyManagerNotes: any;
  delete = true;

  max_char_repairNotes = ENUMS.USER_INPUT_LIMIT_SHORT;
  reached_max_char_repairNotes = false;
  reached_max_char_propertyManagerNotes = false;

  constructor(
    public dialogRef: MatDialogRef<NotesDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data && data.tenantNotes) {
      this.tenantNotes = data.tenantNotes;
    }

    if (data && data.landlordNotes) {
      this.propertyManagerNotes = data.landlordNotes;
    }
  }

  userInput(type: string) {
    switch (type) {
      case 'repairNotes':
        if (this.tenantNotes && this.tenantNotes.length >= this.max_char_repairNotes) {
          this.reached_max_char_repairNotes = true;
        } else {
          this.reached_max_char_repairNotes = false;
        }
        break;
      case 'propertyManagerNotes':
        if (this.propertyManagerNotes && this.propertyManagerNotes.length >= this.max_char_repairNotes) {
          this.reached_max_char_propertyManagerNotes = true;
        } else {
          this.reached_max_char_propertyManagerNotes = false;
        }
        break;
    }
  }

  onOk() {
    let data = {
      tenantNotes: this.tenantNotes,
      propertyManagerNotes: this.propertyManagerNotes,
    }
    this.dialogRef.close(data);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
