<div class="work-order-files-viewer pt-5 pb-5">
    <div class="d-flex">
        <h2 class="mb-3 ml-2 featured-main-title relative">Files</h2>
        <fa-icon style="font-size: 1.5rem" (click)="copyText(files[currentIndex])" [icon]="faCopy"
            class="ml-2"></fa-icon>
    </div>

    <span class="dash"></span>

    <!-- <div *ngFor="let property of propertyDetails">
        <app-property-card [propertyDetail]="property"></app-property-card>
    </div> -->

    <ngx-slick-carousel class="carousel" #slickCarousel="slick-carousel" [config]="slideConfig"
        (afterChange)="onAfterChangeSlide($event)">
        @for (file of files; track file.index) {
        <div *ngIf="!isVideo(file.type)" ngxSlickItem class="slide d-flex flex-column align-items-center">
            <img fill src="{{ file.image }}" class="carousel-img" alt="" width="100%">
            <div>{{ file.name }}</div>
            <div *ngIf="file.publicUrl">{{ file.publicUrl }}</div>
        </div>
        <div *ngIf="isVideo(file.type)" class="d-flex flex-column">
            <video controls class="video-fluid d-flex flex-column align-items-center">
                <source [src]="file.image" type="{{file.type}}" />
            </video>
            <div class="d-flex flex-column align-items-center">
                <div class="d-flex">
                    <div>{{ file.name }}</div>
                </div>
                <div *ngIf="file.publicUrl">{{ file.publicUrl }}</div>
            </div>
        </div>

        }
    </ngx-slick-carousel>
</div>