
import {
  Component,
  ElementRef,
  QueryList,
  Inject,
  Input,
  OnDestroy,
  Optional,
  Self,
  ViewChild,
  ViewChildren,
  forwardRef,
  OnInit,
  ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FocusMonitor } from '@angular/cdk/a11y';
import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { provideNativeDateAdapter } from '@angular/material/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from '@angular/forms';
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
  MatFormFieldModule,
} from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher } from '@angular/material/core';
import { Sort, MatSortModule } from '@angular/material/sort';
import { GoogleMapsModule, MapMarker } from '@angular/google-maps';
import { TranslateModule } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faSquarePlus, faPlusSquare, faTrashCan, faSave, faEdit, faUsersRectangle, faUserCheck, faUser, faUsers, faHomeUser, faHouseUser, faUserCog, faPhone, faMapLocationDot, faFileUpload, faFile, faFileCirclePlus, faFileImport, faFileImage } from '@fortawesome/free-solid-svg-icons';
import { Observable, forkJoin, lastValueFrom } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AppServiceService } from '../services/app-service.service';
import { MessageDialogComponent } from '../message-dialog/message-dialog.component';
import { environment } from '../../env/environment';
import { ENUMS } from '../../models/constants';
import { NotesDialogComponent } from '../notes-dialog/notes-dialog.component';
import { ProgressBarComponent } from '../progress-bar/progress-bar.component';
import { WorkOrderFilesViewerComponent } from '../work-order-files-viewer/work-order-files-viewer.component';
@Component({
  selector: 'app-work-order',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    GoogleMapsModule,
    TranslateModule,
    FontAwesomeModule,
    MatSortModule,
    RouterModule,
  ],
  templateUrl: './work-order.component.html',
  styleUrl: './work-order.component.scss',
  providers: [
    provideNativeDateAdapter(),
    // {
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: { siteKey: environment.reCaptchaSiteKey } as RecaptchaSettings,
    // },
  ],
})
export class WorkOrderComponent {
  @ViewChild('fileInput') fileInput!: ElementRef;
  // @ViewChild('fileInputUpdate') fileInputUpdate!: ElementRef;
  @ViewChildren('fileInputUpdate') fileInputUpdate!: QueryList<ElementRef>;

  ENUMS = ENUMS;

  faSquarePlus = faSquarePlus;
  faPlusSquare = faPlusSquare;
  faTrashCan = faTrashCan;
  faSave = faSave;
  faEdit = faEdit;
  faUsersRectangle = faUsersRectangle;
  faUserCheck = faUserCheck;
  faUser = faUser;
  faHomeUser = faHomeUser;
  faHouseUser = faHouseUser;
  faUserCog = faUserCog;
  faPhone = faPhone;
  faMapLocationDot = faMapLocationDot;
  faFileUpload = faFileUpload;
  faFile = faFile;
  faFileCirclePlus = faFileCirclePlus;
  faFileImport = faFileImport;
  faFileImage = faFileImage;

  repairItem: any;

  repairItemReset: any = {
    woStatus: null,
    repairItem: null,
    repairCost: null,
    repairOpenedDate: null,
    repairedDate: null,
    repairNotes: null,
    address: null,
    vendor: null,
    propertyManagerNotes: null,
    filePaths: [],
  }

  vendors: any = [
    { name: 'Yen Shi Fu', email: ['desong.e.yu@gmail.com', 'desong.e.yu@gmail.com'], id: 'yanshifuhandyman' },
    { name: 'Modern Mechanical+1', email: ['desong.e.yu+1@gmail.com', 'desong.e.yu+1@gmail.com'], id: 'mmhvac' },
    { name: 'Axis Plumbing+2', email: ['desong.e.yu+2@gmail.com', 'desong.e.yu+2@gmail.com'], id: 'axisplumbing' }
    // { name: 'Yen Shi Fu Handyman', email: 'yenchiehs@hotmail.com', id: 'yanshifuhandyman'},
    // { name: 'Axis Plumbing', email: 'axisplumbingnc@gmail.com', id: 'axisplumbing'},
    // { name: 'Southern Blue Energy HVAC', email: 'laurencemou@hotmail.com', id: 'sbehvac' },
    // { name: 'Modern Mechanical HVAC', email: 'solutions@mmhvac.com', id: 'mmhvac' },
    // { name: 'Excel Tech Heat and Air HVAC(Raul)', email: 'service@exceltechheatandair.com', id: 'exceltechhvac' },
    // { name: 'Grand Opening Garage Door', email: 'mike@grandopeningsnc.com', id: 'grandopening' },
    // { name: 'ACE Handyman', email: 'trianglejoconc@acehandymanservices.com', id: 'acehandyman' },
    // { name: 'RHS Appliance Repair', email: 'Rhsappliancerepair@gmail.com.', id: 'rhsappliance' },
  ]

  woStatuses: any = [
    'Open',
    'Assigned',
    'Scheduled',
    'Completed',
  ]

  selectedVendor: any = [];
  tenantNotes: any = [];
  propertyManagerNotes: any = [];
  repairCost: any = [];
  woStatus: any = [];
  repairedDate: any = [];
  filePaths: any = [];
  filePathsSignedURLPerWO: any = []

  max_char_repairNotes = ENUMS.USER_INPUT_LIMIT_SHORT;
  reached_max_char_repairNotes = false;
  reached_max_char_propertyManagerNotes = false;
  reached_max_char_repairCost = false;
  max_char_repairCost = ENUMS.USER_INPUT_LIMIT_COST_SMALL;
  max_charLen_repairCost = ENUMS.USER_INPUT_LIMIT_COST_LENGTH_SMALL;

  isLandlordGroup = false;
  isTenantGroup = true;

  tenantInfo: any;
  workOrderCounts: any = {};

  environment = environment;

  session: any;

  file: any;
  fileUpdate: any = [];
  fileUpdateCurrentIndex: number = 0;
  message: string = '';
  fileUpdateMessage: any = [];
  canUpload = false;
  totalSize = 0;
  totalAllowedFileSizePerWO = 8.3e6;

  properties: any;

  urlFavProperties = environment.rootUrl + 'prod/favProperties/';
  urlTenants = environment.rootUrl + 'prod/tenants/';
  urlWorkOrderCounts = environment.rootUrl + 'prod/workOrderCounts/';
  urlWorkOrder = environment.rootUrl + 'prod/workOrder/';
  urlWorkOrderFileUpload = `${environment.rootUrl}prod/workOrder/upload`;

  disableRepairNotes = false;

  userConsented = false;

  progressBarDialogRef: any;
  woConfirmationDialogRef: any;

  updatedWorkOrderNeedSave: any = [];

  // Filter Options
  showOpen = true;
  showAssigned = true;
  showScheduled = true;
  showCompleted = false;
  showAddress = null;
  sortedRepairs: any = []; // doesn't have sort since was going to use mat-sort, it doesn't work on selectable elements
  filteredRepairs: any = [];

  repairNoteHint = null;

  urlSelectedVendor = null;
  urlSelectedWoId = null;
  urlSelectedAddress = null;

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    // .set('Access-Control-Allow-Origin', '*')
    // .set('Access-Control-Allow-Methods', 'OPTIONS,POST')
    // .set("Access-Control-Allow-Headers", 'Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token')
    // .set('Authorization', `Bearer ${environment.mlsBearToken}`)
    .set('Content-Type', `application/json`);


  constructor(
    public authService: AuthService,
    private httpClient: HttpClient,
    public dialog: MatDialog,
    private builder: FormBuilder,
    public appService: AppServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.repairItem = JSON.parse(JSON.stringify(this.repairItemReset));
  }

  // Process the URL with query params that user launch when click on a vendor in WO email sent by Tenant or Property Manager
  processUrlSelectedVendor() {
    this.route.queryParams.subscribe(params => {
      this.urlSelectedVendor = params['vendor'];
      this.urlSelectedWoId = params['woId'];
      this.urlSelectedAddress = params['address'];

      let req = {
        workOrderId: this.urlSelectedWoId,
        address: this.urlSelectedAddress,
      }

      // Get work order by workOrderID and address
      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      this.httpClient.post(this.urlWorkOrder, req, { headers: this.httpHeaders, observe: 'response' }).subscribe((res: any) => {
        console.log('res is: ' + JSON.stringify(res))

        if (res.body.length > 0) {
          let repair = res.body[0];

          let vendor = this.vendorLookup();
          if (vendor) {
            repair['vendor'] = vendor;
            repair['woStatus'] = "Assigned";

            // Update work order with updated vendor and status
            this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
            this.httpClient.put(this.urlWorkOrder, repair, { headers: this.httpHeaders, observe: 'response' }).subscribe(
              (data: any) => {
                this.sendWOToVendorEmail(repair);
              }
            )
          } else {
            const dialogRef = this.dialog.open(MessageDialogComponent, {
              width: '30%',
              data: { message: "Unknown vendor.", okOnly: true }
            });
            console.log('Unknown vendor')
          }
        }
      })

    });
  }

  async ngOnInit() {
    // test
    // this.openProgressBar();
    // this.closeProgressBar();
    // this.workOrderConfirmationModal();

    this.isLandlordGroup = await this.authService.isInGroup(ENUMS.LANDLORD_GROUP);

    this.isTenantGroup = await this.authService.isInGroup(ENUMS.TENANT_GROUP);

    this.session = await this.authService.getCurrentSession();

    // Try to automatically detect if this is tenant if there is data in Tenants table
    // if (this.isTenantGroup) {
    //   this.disableRepairNotes = true;
    //   this.getTenantInfo(); // Only works for tenant, for landlord's id won't be in tenant table
    // }
    this.getTenantInfo().then(() => {
      if (this.tenantInfo && this.tenantInfo.tenantId) {
        // this.isTenantGroup = true;
        this.disableRepairNotes = true;
      }
    })

    if (this.isLandlordGroup) {
      this.getProperties();
    }

    // this.route.queryParams.subscribe(params => {
    //   this.urlSelectedVendor = params['vendor'];
    //   this.urlSelectedWoId = params['woId'];
    //   this.urlSelectedAddress = params['address'];

    //   let req = {
    //     workOrderId: this.urlSelectedWoId,
    //     address: this.urlSelectedAddress,
    //   }

    //   // Get work order by workOrderID and address
    //   this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    //   this.httpClient.post(this.urlWorkOrder, req, { headers: this.httpHeaders, observe: 'response' }).subscribe((res: any) => {
    //     console.log('res is: ' + JSON.stringify(res))

    //     if (res.body.length > 0) {
    //       let repair = res.body[0];

    //       let vendor = this.vendorLookup();
    //       if (vendor) {
    //         repair['vendor'] = vendor;
    //         repair['woStatus'] = "Assigned";

    //         // Update work order with updated vendor and status
    //         this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    //         this.httpClient.put(this.urlWorkOrder, repair, { headers: this.httpHeaders, observe: 'response' }).subscribe(
    //           (data: any) => {
    //             this.sendWOToVendorEmail(repair);
    //           }
    //         )
    //       } else {
    //         const dialogRef = this.dialog.open(MessageDialogComponent, {
    //           width: '30%',
    //           data: { message: "Unknown vendor.", okOnly: true }
    //         });
    //         console.log('Unknown vendor')
    //       }
    //     }
    //   })

    // });

    setTimeout(() => {
      // Only get all WO at WO main screen if not coming from a direct URL link to select vendor
      if (!this.urlSelectedVendor) {
        this.showOpen = true;
        this.showAssigned = true;
        this.showScheduled = true;
        this.showCompleted = false;
        this.getWorkOrderByStatus(["Open", "Assigned", "Scheduled"]);
      }

      // Test
      // this.noteModal(0)
    }, 500)


    // Test
    // setTimeout(() => {
    //   this.viewFileUpload(0)
    // }, 1000)
  }

  vendorLookup() {
    return this.vendors.find(v => v.id === this.urlSelectedVendor)
  }

  onSelectItem() {
    switch (this.repairItem.repairItem) {
      case 'refrigerator':
      case 'range':
      case 'range hood':
      case 'dish washer':
      case 'oven':
        this.repairNoteHint = 'Please ensure to include: 1. picture of the model and serial number of the unit, and 2. nature of issue with duration.'
        break;
      case 'bathroom':
      case 'fire place':
        this.repairNoteHint = 'Please ensure to include: 1. floor affected, 2. picture of the item.'
        break;
      case 'hvac':
        this.repairNoteHint = 'Please ensure to include: 1. floor affected, 2. if warm/cold air from vents, and 3. picture of the thermostat.'
        break;
      default:
        this.repairNoteHint = 'Please ensure to include all relevant details to ensure timely and accurate response, e.g. floor, duration, pictures'
    }
  }

  setPropertyManagerPredefinedNotes(woItem) {
    if (!woItem.propertyManagerNotes) {
      woItem['propertyManagerNotes'] = '';
    }

    switch (woItem.repairItem) {
      case 'refrigerator':
      case 'range':
      case 'range hood':
      case 'dish washer':
      case 'oven':
        woItem['propertyManagerNotes'] = 'Please text Property Manager first with quote. After approval, please contact tenant directly to schedule work. Thank you.'
        break;
      case 'bathroom':
      case 'fire place':
        // woItem['propertyManagerNotes'] = ''
        break;
      case 'hvac':
        woItem['propertyManagerNotes'] = 'Please 1. contact tenant directly to schedule, 2. email Property Manager with scheduled date/time as FYI, 3. when onsite, call Property Manager for approval before fix. Thank you.'
        break;
      default:
      // woItem['propertyManagerNotes'] = ''
    }
  }

  getProperties() {
    try {
      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      this.httpClient.get(this.urlFavProperties, { headers: this.httpHeaders, observe: 'response' }).subscribe(
        (data: any) => {
          this.properties = data.body;

          // const property = this.properties.find((p: any) => p.address === '1041_Pueblo_Ridge_Place__Cary_NC_27519-1725492639260');
          // this.getTenantInfoAsLandlord(property.tenantID);

          this.processUrlSelectedVendor();
        },
        (error) => {
          console.log(error);
        }
      );
    }
    catch (e) {
      console.log(e);
    }
  }

  onSelectRepairAddress() {
    console.log(this.repairItem.address)

    const property = this.properties.find((p: any) => p.address === this.repairItem.address);
    this.getTenantInfoAsLandlord(property.tenantID);

    this.getWorkOrderCountsInfo();
  }

  onSelectFilterAddress() {
    // this.showAddress = this.properties.find((p: any) => p.address === this.repairItem.address);
  }

  applyFilterMain() {
    let statuses = [];
    this.showOpen ? statuses.push('Open') : '';
    this.showAssigned ? statuses.push('Assigned') : '';
    this.showScheduled ? statuses.push('Scheduled') : '';
    this.showCompleted ? statuses.push('Completed') : '';
    this.getWorkOrderByStatus(statuses, this.showAddress);
  }

  applyFilter() {
    let tempFiltered = [];

    if (this.showAddress) { //show all selection
      tempFiltered = this.sortedRepairs.filter((r: any) => {
        if (this.showAddress && r.address === this.showAddress) {
          return true;
        } else {
          return false;
        }
      })
    }

    if (tempFiltered && tempFiltered.length === 0) {
      if (this.showAddress) {
        this.filteredRepairs = tempFiltered;
        return;
      } else {
        tempFiltered = this.sortedRepairs;
      }
    }

    tempFiltered = tempFiltered.filter((r: any) => {
      if (this.showOpen && r.woStatus === 'Open') {
        return true;
      }

      if (this.showAssigned && r.woStatus === 'Assigned') {
        return true;
      }

      if (this.showScheduled && r.woStatus === 'Scheduled') {
        return true;
      }

      if (this.showCompleted && r.woStatus === 'Completed') {
        return true;
      }
      return false;
    })

    this.filteredRepairs = tempFiltered;
  }

  filterValid() {
    if (this.showCompleted && !this.showAddress) {
      return false;
    }
    return true;
  }

  // NOT USED
  addRepairItem() {
    // this.selectedProperty.repairs.push({
    //   repairDate: this.repairItem.repairDate,
    //   repairItem: this.repairItem.repairItem,
    //   repairCost: this.repairItem.repairCost,
    //   repairNotes: this.repairItem.repairNotes,
    // })

    // this.sortedRepairs = this.selectedProperty.repairs.slice();

    // this.sortedRepairs.push({
    //   repairedDate: this.repairItem.repairedDate,
    //   repairItem: this.repairItem.repairItem,
    //   repairCost: this.repairItem.repairCost,
    //   repairNotes: this.repairItem.repairNotes,
    // })
    this.sortedRepairs.push(this.repairItem);

    this.repairItem = JSON.parse(JSON.stringify(this.repairItemReset));
  }

  userInput(type: string) {
    switch (type) {
      case 'repairCost':
        if (this.repairItem.repairCost >= this.max_char_repairCost) {
          setTimeout(() => {
            this.repairItem.repairCost = Number((this.repairItem.repairCost + '').slice(0, Number(this.max_charLen_repairCost)));
          }, 300);
          this.reached_max_char_repairCost = true;
        } else {
          this.reached_max_char_repairCost = false;
        }
        break;
      case 'repairNotes':
        if (this.repairItem.repairNotes && this.repairItem.repairNotes.length >= this.max_char_repairNotes) {
          this.reached_max_char_repairNotes = true;
        } else {
          this.reached_max_char_repairNotes = false;
        }
        break;
      case 'propertyManagerNotes':
        if (this.propertyManagerNotes && this.propertyManagerNotes.length >= this.max_char_repairNotes) {
          this.reached_max_char_propertyManagerNotes = true;
        } else {
          this.reached_max_char_propertyManagerNotes = false;
        }
        break;
    }
  }


  sortData(sort: Sort) {
    // return; // only works for simple type not input so ignore sort

    const data = this.filteredRepairs.slice();
    if (!sort.active || sort.direction === '') {
      this.filteredRepairs = data;
      return;
    }

    this.filteredRepairs = data.sort((a: any, b: any) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'woId':
          return this.compare(a.workOrderId, b.workOrderId, isAsc);
        case 'status':
          return this.compare(a.woStatus, b.woStatus, isAsc);
        case 'openedDate':
          return this.compare(a.repairOpenedDate, b.repairOpenedDate, isAsc);
        case 'repairedDate':
          return this.compare(a.repairedDate, b.repairedDate, isAsc);
        case 'item':
          return this.compare(a.repairItem, b.repairItem, isAsc);
        case 'cost':
          return this.compare(a.repairCost, b.repairCost, isAsc);
        case 'notes':
          return this.compare(a.repairNotes, b.repairNotes, isAsc);
        case 'propertyManagernotes':
          return this.compare(a.propertyManagerNotes, b.propertyManagerNotes, isAsc);
        case 'vendor':
          return this.compare(a.vendor, b.vendor, isAsc);
        case 'address':
          return this.compare(a.address, b.address, isAsc);
        default:
          return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  showOpenedDate(item: any) {
    return item.repairOpenedDate ? new Date(item.repairOpenedDate).toDateString() : null;
  }

  showRepairedDate(item: any) {
    return item.repairedDate ? new Date(item.repairedDate).toDateString() : null;
  }

  removeRepairItem(index: number) {
    this.openDialogRemoveRepairItem(index);
  }

  // noteModal(index: number) {
  noteModal(repair: any) {
    const dialogRef = this.dialog.open(NotesDialogComponent, {
      width: '50%',
      data: {
        tenantNotes: repair.repairNotes, //this.tenantNotes[index],
        landlordNotes: repair.propertyManagerNotes, //this.propertyManagerNotes[index],
      }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('The dialog was closed');
      console.log(result);

      if (result) {
        // this.tenantNotes[index] = result.tenantNotes;
        // this.sortedRepairs[index].tenantNotes = result.tenantNotes;

        // this.propertyManagerNotes[index] = result.propertyManagerNotes;

        repair.repairNotes = result.tenantNotes;
        repair.propertyManagerNotes = result.propertyManagerNotes;

        this.updatedWorkOrderFiltered(null, repair)
      }
    })
  }

  openDialogRemoveRepairItem(index: any) {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '50%',
      data: { message: `Please confirm you want to delete WO# ${this.sortedRepairs[index].workOrderId} ${this.sortedRepairs[index].repairItem} at ${this.getDisplayAddress(this.sortedRepairs[index].address)}`, okOnly: false }
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log('The dialog was closed');
      console.log(result);

      if (result) {
        // remove from DB
        this.removeFromWorkOrderTable(this.sortedRepairs[index])

        this.sortedRepairs.splice(index, 1);
      }
    })
  }

  async getTenantInfo() {
    // if (this.isTenantGroup) {
    this.tenantInfo = await this.getTenantsTableInfo();
    if (this.tenantInfo && this.tenantInfo.body) {
      this.tenantInfo = this.tenantInfo.body[0];
    }

    await this.getWorkOrderCountsInfo();
    // }
  }

  async getWorkOrderCountsInfo() {
    // if (this.isTenantGroup) { // both tenant and landlord update WO count table
    this.workOrderCounts = await this.getWorkOrderCountsTableInfo();
    if (this.workOrderCounts && this.workOrderCounts.body) {
      this.workOrderCounts = this.workOrderCounts.body[0];
    }
    // }
  }

  async getTenantInfoAsLandlord(tenantId: any) {
    this.tenantInfo = await this.getTenantsTableInfo(tenantId);
    if (this.tenantInfo && this.tenantInfo.body) {
      this.tenantInfo = this.tenantInfo.body[0];

      // this.getWorkOrderCountsInfo();
    }
    // this.getWorkOrderCountsInfo(); // both tenant and landlord get WO count
  }

  addToWorkOrderCountsTable() {
    let data = {
      address: this.repairItem.address,
      workOrderTotal: this.workOrderCounts.workOrderTotal,
    }
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    this.httpClient.put(this.urlWorkOrderCounts, data, { headers: this.httpHeaders, observe: 'response' }).subscribe(
      (data: any) => {
        console.log(data)
      }
    )
  }

  async getTenantsTableInfo(tenantId: any = null) {
    try {

      let urlTenants = this.urlTenants;
      if (tenantId) {
        urlTenants += tenantId;
      }

      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      return lastValueFrom(this.httpClient.get(urlTenants, { headers: this.httpHeaders, observe: 'response' }));
    }
    catch (e) {
      throw e;
      console.log(e);
    }
  }

  async getTenantsTableInfoByAddress(address: any = null) {
    try {

      let urlTenants = this.urlTenants;
      if (address) {
        urlTenants += address;
      }

      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      return lastValueFrom(this.httpClient.post(urlTenants, {}, { headers: this.httpHeaders, observe: 'response' }));
    }
    catch (e) {
      throw e;
      console.log(e);
    }
  }

  async getTenantsTableInfoById(id: any = null) {
    try {

      let urlTenants = this.urlTenants;
      if (id) {
        urlTenants += id;
      }

      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      return lastValueFrom(this.httpClient.get(urlTenants, { headers: this.httpHeaders, observe: 'response' }));
    }
    catch (e) {
      throw e;
      console.log(e);
    }
  }

  async getWorkOrderCountsTableInfo() {
    try {
      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      return lastValueFrom(this.httpClient.get(this.urlWorkOrderCounts + this.repairItem.address, { headers: this.httpHeaders, observe: 'response' }));
    }
    catch (e) {
      throw e;
      console.log(e);
    }
  }

  workOrderValid() {

    // Test
    // return true;

    let valid = false;

    if (this.repairItem.repairItem && this.repairItem.repairNotes && this.file && this.file.length > 0 && this.isTenantGroup && this.userConsented) {
      valid = true;
    }

    if (this.repairItem.address && this.repairItem.repairItem && this.isLandlordGroup) {
      valid = true;
    }

    return valid;
  }

  removeEmptyAttributes() {
    let tempProperty = JSON.parse(JSON.stringify(this.repairItem));
    let keyArray: any = [];
    Object.values(tempProperty).forEach((v: any, index: number) => {
      if (!v || v.length === 0) {
        keyArray.push(Object.keys(tempProperty)[index]);
      }
    })

    keyArray.forEach((i: any) => {
      delete tempProperty[i];
    })

    this.repairItem = JSON.parse(JSON.stringify(tempProperty));
  }


  // SAVE WORK ORDER
  async saveWorkOrder(repairIndex: number, repair: any) {

    this.openProgressBar();

    // Duplicate, set to false after successful update WO
    // this.updatedWorkOrderNeedSave[repairIndex] = false;

    console.log(this.sortedRepairs[repairIndex].address)
    if (this.selectedVendor[repairIndex]) {
      console.log(this.selectedVendor[repairIndex].name)
    }

    // Update work order table for this WO 1. status, 2. landlordnotes 3. If vendor, then send email to vendor
    // let updateRepairItem = this.sortedRepairs[repairIndex];
    let updateRepairItem: any;

    // this.sortedRepairs[repairIndex].woStatus = this.woStatus[repairIndex];
    // this.sortedRepairs[repairIndex].vendor = this.selectedVendor[repairIndex];
    // this.sortedRepairs[repairIndex].repairNotes = this.tenantNotes[repairIndex];
    // this.sortedRepairs[repairIndex].propertyManagerNotes = this.propertyManagerNotes[repairIndex];
    // this.sortedRepairs[repairIndex].repairCost = this.repairCost[repairIndex];
    // this.sortedRepairs[repairIndex].repairedDate = this.repairedDate[repairIndex];

    this.updateProgress(25)
    // await this.saveAddedFilesToS3(repairIndex);
    await this.saveAddedFilesToS3Filtered(repair);
    this.updateProgress(75)

    // this.sortedRepairs[repairIndex].filePaths = this.filePaths[repairIndex];

    updateRepairItem = this.filteredRepairs[repairIndex];

    // new approach to filter
    // updateRepairItem = {};
    // updateRepairItem = repair; // once convert each attribute to new method of setting directly on repair obj, can remove this
    updateRepairItem['woStatus'] = repair.woStatus;
    updateRepairItem['repairNotes'] = repair.repairNotes;
    updateRepairItem['propertyManagerNotes'] = repair.propertyManagerNotes;
    updateRepairItem['vendor'] = repair.vendor;
    updateRepairItem['repairCost'] = repair.repairCost;
    updateRepairItem['repairedDate'] = repair.repairedDate;
    updateRepairItem['filePaths'] = repair.filePaths;

    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    this.httpClient.put(this.urlWorkOrder, updateRepairItem, { headers: this.httpHeaders, observe: 'response' }).subscribe(
      (data: any) => {
        console.log("successed");

        let statuses = [];
        this.showOpen ? statuses.push('Open') : '';
        this.showAssigned ? statuses.push('Assigned') : '';
        this.showScheduled ? statuses.push('Scheduled') : '';
        this.showCompleted ? statuses.push('Completed') : '';
        this.getWorkOrderByStatus(statuses, this.showAddress);

        this.updatedWorkOrderNeedSave[repairIndex] = false;

        // Tenant
        if (this.isTenantGroup) {
          this.closeProgressBar()
          this.workOrderConfirmationModal();
        }

        // Landlord: if vendor selected, send email
        if (typeof repair.vendor === 'object'
          && this.isLandlordGroup
        ) {
          this.updateProgress(85);

          setTimeout(() => {
            this.closeProgressBar()

            const dialogRef = this.dialog.open(MessageDialogComponent, {
              width: '70%',
              // TODO add back after fix vendor
              data: { message: `Please confirm you want to send WO# ${updateRepairItem.workOrderId} ${updateRepairItem.repairItem} to ${updateRepairItem.vendor.name}`, okOnly: false }
              // data: { message: `Please confirm you want to send WO# to vendor`, okOnly: false }
            });

            dialogRef.afterClosed().subscribe(result => {

              if (result) {
                console.log("Send to vendor email")
                this.sendWOToVendorEmail(repair);
              } else {
                this.closeProgressBar()
              }
              console.log("Canceled send vendor email")
            });
          }, 5000)
        } else {
          this.updateProgress(85);
          this.closeProgressBar();
          this.workOrderConfirmationModal();
        }
      },
      (error) => {
        console.log('error updating WO: ' + error);
      }
    )
  }

  async saveAddedFilesToS3(repairIndex: number) {
    // Save added filePaths
    if (this.fileUpdate[repairIndex] && this.fileUpdate[repairIndex].length > 0) {

      // Full file path including address
      let updatedFileNames = this.getS3FilePaths(this.fileUpdate[repairIndex], this.sortedRepairs[repairIndex].address);
      // woItem.filePaths = updatedFileNames;

      if (!this.filePaths[repairIndex]) {
        this.filePaths[repairIndex] = [];
      }

      updatedFileNames.forEach((fileName, index) => {
        this.filePaths[repairIndex].push({
          path: fileName,
          type: this.fileUpdate[repairIndex][index].type,
          size: this.fileUpdate[repairIndex][index].size,
        })
      })

      let allFileSignedUrls: Observable<any>[] = [];
      let updatedAddrFileNames: string[] = [];

      // // Just file name
      updatedFileNames = this.getS3FilePaths(this.fileUpdate[repairIndex]);

      let updatedFileNamesIndex = 0;
      for (const file of this.fileUpdate[repairIndex]) {
        try {
          // updatedAddrFileNames.push(this.repairItem.address + '/' + updatedFileNames[updatedFileNamesIndex]);

          const data = {
            // fileName: updatedFileNames[updatedFileNamesIndex],
            fileName: updatedFileNames[updatedFileNamesIndex],
            contentType: file.type,
            address: this.sortedRepairs[repairIndex].address,
            signedUrl: true,
          };
          let signedUrlPromise = this.getUploadUrl(data);

          if (signedUrlPromise) {
            allFileSignedUrls.push(signedUrlPromise);
          }

          ++updatedFileNamesIndex;
        }
        catch (e) {
          console.log(e);
        }
      }

      forkJoin(allFileSignedUrls).subscribe({
        next: (responses) => {

          this.updateProgress(40)

          console.log('Got all file signed URL succeeded:', responses);

          let allFileUploads: Observable<any>[] = [];

          responses.forEach((res: any, index: number) => {
            const uploadUrl = res.body.uploadUrl;
            let fileUploadPromise = this.uploadFileToS3(updatedFileNames[index], this.fileUpdate[repairIndex][index], uploadUrl)

            if (fileUploadPromise) {
              allFileUploads.push(fileUploadPromise);
            }
          })

          forkJoin(allFileUploads).subscribe({
            next: (responses) => {

              this.updateProgress(65)

              // Reset fileUpdate that was just added
              this.fileUpdate[repairIndex] = null;
              // this.fileInputUpdate.nativeElement.value = '';

              console.log('All added files upload calls succeeded:', responses);
            },
            error: (error) => {
              console.error('Error in file upload calls:', error);
            }
          })
        },
        error: (error) => {
          console.error('Error in getting signed url calls:', error);
        }
      });
    }
  }

  async saveAddedFilesToS3Filtered(repair: any) {
    // Save added filePaths
    if (repair.fileUpdate && repair.fileUpdate.length > 0
      && repair.fileUpdate[0].name
      && repair.fileUpdate[0].name.length > 0
    ) {

      // Full file path including address
      let updatedFileNames = this.getS3FilePaths(repair.fileUpdate, repair.address);
      // woItem.filePaths = updatedFileNames;

      if (!repair.filePaths) {
        repair.filePaths = [];
      }

      updatedFileNames.forEach((fileName, index) => {
        repair.filePaths.push({
          path: fileName,
          type: repair.fileUpdate[index].type,
          size: repair.fileUpdate[index].size,
        })
      })

      let allFileSignedUrls: Observable<any>[] = [];
      let updatedAddrFileNames: string[] = [];

      // // Just file name
      updatedFileNames = this.getS3FilePaths(repair.fileUpdate);

      let updatedFileNamesIndex = 0;
      for (const file of repair.fileUpdate) {
        try {
          // updatedAddrFileNames.push(this.repairItem.address + '/' + updatedFileNames[updatedFileNamesIndex]);

          const data = {
            // fileName: updatedFileNames[updatedFileNamesIndex],
            fileName: updatedFileNames[updatedFileNamesIndex],
            contentType: file.type,
            address: repair.address,
            signedUrl: true,
          };
          let signedUrlPromise = this.getUploadUrl(data);

          if (signedUrlPromise) {
            allFileSignedUrls.push(signedUrlPromise);
          }

          ++updatedFileNamesIndex;
        }
        catch (e) {
          console.log(e);
        }
      }

      // let repairBackup = JSON.parse(JSON.stringify(repair));

      forkJoin(allFileSignedUrls).subscribe({
        next: (responses) => {

          // repair = repairBackup;

          this.updateProgress(40)

          console.log('Got all file signed URL succeeded:', responses);

          let allFileUploads: Observable<any>[] = [];

          responses.forEach((res: any, index: number) => {
            const uploadUrl = res.body.uploadUrl;
            let fileUploadPromise = this.uploadFileToS3(updatedFileNames[index], repair.fileUpdate[index], uploadUrl)

            if (fileUploadPromise) {
              allFileUploads.push(fileUploadPromise);
            }
          })

          forkJoin(allFileUploads).subscribe({
            next: (responses) => {

              this.updateProgress(65)

              // Reset fileUpdate that was just added
              delete repair['fileUpdate'];
              delete repair['fileUpdateMessage'];
              delete repair['updatedWorkOrderNeedSave'];

              this.fileInputUpdate.toArray()[this.fileUpdateCurrentIndex].nativeElement.value = '';

              console.log('All added files upload calls succeeded:', responses);
            },
            error: (error) => {
              console.error('Error in file upload calls:', error);
            }
          })
        },
        error: (error) => {
          console.error('Error in getting signed url calls:', error);
        }
      });
    }
  }

  // async sendWOToVendorEmail(repairIndex: any) {
  async sendWOToVendorEmail(repair: any) {
    this.openProgressBar();

    if (this.isLandlordGroup && this.properties) {
      // let tenantId: any = await this.getTenantsTableInfoByAddress(
      //   repair.address
      // );
      // if (tenantId && tenantId.body && tenantId.body.length > 0) {
      //   this.tenantInfo = tenantId.body[0];
      //   // await this.getTenantInfoAsLandlord(tenantId); // don't need since already got tenatn info via address GSI
      // }

      let favProperty = this.properties.find((p: any) => p.address === repair.address)
      let favPropertyTenantID = favProperty ? favProperty.tenantID : '';

      let tenantId: any = await this.getTenantsTableInfoById(
        favPropertyTenantID
      );
      if (tenantId && tenantId.body && tenantId.body.length > 0) {
        this.tenantInfo = tenantId.body[0];
      }
    }

    if (this.isTenantGroup) {
      // If tenant, they don't select address but auto set in Tenants table
      this.repairItem.address = this.tenantInfo.address;

      await this.getTenantInfo()
    }

    this.updateProgress(50)

    // if (this.propertyManagerNotes[repairIndex]) {
    //   this.sortedRepairs[repairIndex].propertyManagerNotes = this.propertyManagerNotes[repairIndex];
    // }

    // if (this.repairCost[repairIndex]) {
    //   this.sortedRepairs[repairIndex].repairCost = this.repairCost[repairIndex];
    // }

    if (this.tenantInfo) {
      this.tenantInfo.phoneToShow = this.appService.showPhoneNumber(this.tenantInfo.phone);
    }

    let repairItem = JSON.parse(JSON.stringify(repair))
    repairItem.address = this.getPlainAddress(repairItem.address);

    const workOrderData = {
      repairItem: repairItem,
      // address: this.tenantInfo.address, // not needed since fileName has full path
      fileName: repair.filePaths ? repair['filePaths'].map((file: any) => file.path) : '',
      propertyManagerContact: {
        email: this.environment.clientEmail,
        phone: this.environment.clientPhone,
      },
      vendor: repair.vendor,
      sendTo: 'Vendor',
      tenant: this.tenantInfo,
    }

    this.updateProgress(75)

    this.sendMail(workOrderData).then(
      (data: any) => {
        console.log(data);
        this.updateProgress(95)
        this.closeProgressBar();
        this.workOrderConfirmationModal();
      }, (error: any) => {
        this.closeProgressBar();
        console.log("Error in sending mail to Vendor, error: " + JSON.stringify(error))
      }
    )
  }

  openProgressBar(): void {
    this.progressBarDialogRef = this.dialog.open(ProgressBarComponent, {
      width: '100%',
      panelClass: 'progressbar-dialog-container',
      data: { progress: 0 },
      disableClose: true,
      hasBackdrop: true
    });
  }

  updateProgress(progressValue: number): void {
    if (this.progressBarDialogRef && this.progressBarDialogRef.componentInstance && 'progress' in this.progressBarDialogRef.componentInstance) {
      this.progressBarDialogRef.componentInstance.progress = progressValue;
    }
  }

  closeProgressBar(): void {
    if (this.progressBarDialogRef) {
      this.progressBarDialogRef.close();
    }
  }

  workOrderConfirmationModal() {
    if (!this.woConfirmationDialogRef) {

      let message = '';
      if (this.isTenantGroup) {
        message = `Thank you for submitting this work order. You should hear back within 24 to 48hrs and have the convenince to track its Status on Work Order.`
      }

      if (this.isLandlordGroup) {
        message = `Work Order Saved.`
      }

      this.woConfirmationDialogRef = this.dialog.open(MessageDialogComponent, {
        width: '60%',
        data: { message: message, okOnly: true }
      });
    }
  }

  resetRepairItemUserInputs() {
    this.repairItem = JSON.parse(JSON.stringify(this.repairItemReset));
    this.file = null;
    this.fileInput.nativeElement.value = '';
    this.userConsented = false;
  }

  async submitWorkOrder() {
    try {

      this.openProgressBar();

      this.removeEmptyAttributes();

      this.repairItem.repairOpenedDate = (new Date()) + '';
      this.repairItem.woStatus = 'Open';

      let woItem = this.repairItem;

      // if (this.tenantInfo && this.tenantInfo.address) { // address already in repairItem
      //   woItem.address = this.tenantInfo.address;
      // }

      // If tenant, they don't select address but auto set in Tenants table
      if (this.isTenantGroup) {
        this.repairItem.address = this.tenantInfo.address;
      }

      // Get tenant info here because don't allow async before sendMail
      if (this.isLandlordGroup) {
        const property = this.properties.find((p: any) => p.address === this.repairItem.address);
        await this.getTenantInfoAsLandlord(property.tenantID);
      }
      this.updateProgress(25)

      if (this.isTenantGroup) {
        await this.getTenantInfo()
      }
      this.updateProgress(35)

      if (!this.workOrderCounts || !this.workOrderCounts.workOrderTotal) {
        this.workOrderCounts = {};
        this.workOrderCounts['workOrderTotal'] = 1;
      } else {
        ++this.workOrderCounts.workOrderTotal;
      }
      woItem.workOrderId = this.workOrderCounts.workOrderTotal;
      // woItem.woStatus = "Open"; // set earlier

      if (this.file && this.file.length > 0) {
        let updatedFileNames = this.getS3FilePaths(this.file, this.repairItem.address);
        // woItem.filePaths = updatedFileNames;

        if (!woItem.filePaths) {
          woItem.filePaths = [];
        }

        updatedFileNames.forEach((fileName, index) => {
          woItem.filePaths.push({
            path: fileName,
            type: this.file[index].type,
            size: this.file[index].size,
          })
        })
      }

      // Add property manager pre-defined notes
      // this.setPropertyManagerPredefinedNotes(woItem);

      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      this.httpClient.put(this.urlWorkOrder, woItem, { headers: this.httpHeaders, observe: 'response' }).subscribe(
        (data: any) => {
          console.log("successed");
          this.updateProgress(55)

          this.addToWorkOrderCountsTable(); // update workOrderTotal in Tenants table

          if (this.file && this.file.length > 0) { // upload files and email files
            // if (this.totalSize < 5e6) { // API Gateway limit is 6MB, beyond need to use signed URL
            //   this.uploadFile();
            // } else {
            this.uploadFileWithSignedUrl();
            // }
          } else {
            // No Files attached path

            // if (this.tenantInfo && this.tenantInfo.name) {
            //   woItem.tenantName = this.tenantInfo.name;
            // }

            // if (this.tenantInfo && this.tenantInfo.phone) {
            //   woItem.tenantPhone = this.tenantInfo.phone;
            // }

            if (this.tenantInfo) {
              this.tenantInfo.phoneToShow = this.appService.showPhoneNumber(this.tenantInfo.phone);
            }

            let repairItem = JSON.parse(JSON.stringify(this.repairItem))
            // repairItem['addressWithID'] = repairItem.address; // doesn't set for some reason, lambda emailworkorer uses tenant.address for address with ID
            repairItem.address = this.getPlainAddress(repairItem.address);

            const workOrderData = {
              repairItem: repairItem,
              // address: this.tenantInfo.address, // not needed since fileName has full path
              fileName: null,
              propertyManagerContact: {
                email: this.environment.clientEmail,
                phone: this.environment.clientPhone,
              },
              sendTo: 'PropertyManager',
              tenant: this.tenantInfo,
            }

            this.sendMail(workOrderData).then(
              (data: any) => {
                console.log(data);
                this.updateProgress(95)
                this.closeProgressBar();
                setTimeout(() => {
                  this.workOrderConfirmationModal();
                  this.resetRepairItemUserInputs();
                }, 500);
              }, (error: any) => {
                console.log("Error in sending mail to Property Manager, error: " + JSON.stringify(error))
              }
            )
          }

          this.showOpen = true;
          this.showAssigned = true;
          this.showScheduled = true;
          this.showCompleted = false;
          this.getWorkOrderByStatus(["Open", "Assigned", "Scheduled"]);
        },
        (error) => {
          console.log('error' + error);
        }
      );
    }
    catch (e) {
      throw e;
      console.log(e);
    }
  }

  triggerFileUpdate(index: number) {
    this.fileUpdateCurrentIndex = index;
    // this.fileInputUpdate.nativeElement.click();
    this.fileInputUpdate.toArray()[index].nativeElement.click();
  }

  // Handle file selection on adding to existing WO
  onFileUpdate(event: Event) {
    let index = this.fileUpdateCurrentIndex;
    console.log("Current index on file Update: " + index)
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.updatedWorkOrder(null, index);
      this.fileUpdate[index] = Array.from(input.files); // Convert file list to array
    }

    if (this.fileUpdate[index] && this.fileUpdate[index].length > 0) {
      this.totalSize = this.fileUpdate[index].reduce((partial: any, f: any) => partial + f.size, 0)

      let existingFilesSize = this.filePaths[index].reduce((partial: any, f: any) => partial + f.size, 0)

      // All files(existing and added) < 10MB
      this.fileUpdateMessage[index] = '';
      if (this.totalSize + existingFilesSize > this.totalAllowedFileSizePerWO) {
        this.fileUpdate[index] = null;
        // this.fileInputUpdate.nativeElement.value = '';
        this.fileUpdateMessage[index] = "Exceeded 9M.";
        if (this.isTenantGroup) {
          this.updatedWorkOrderNeedSave[index] = false;
        }
      }
    }
  }

  onFileUpdateFiltered(event: Event, index: any, repair: any) {
    // let index = this.fileUpdateCurrentIndex;
    // console.log("Current index on file Update: " + index)
    // const input = event.target as HTMLInputElement;

    const input = this.fileInputUpdate.toArray()[index].nativeElement;
    if (input.files && input.files.length > 0) {
      this.updatedWorkOrderFiltered(null, repair);
      repair.fileUpdate = Array.from(input.files); // Convert file list to array
    }

    if (repair.fileUpdate && repair.fileUpdate.length > 0) {
      this.totalSize = repair.fileUpdate.reduce((partial: any, f: any) => partial + f.size, 0)

      let existingFilesSize = repair.filePaths.reduce((partial: any, f: any) => partial + f.size, 0)

      // All files(existing and added) < 10MB
      repair.fileUpdateMessage = '';
      if (this.totalSize + existingFilesSize > this.totalAllowedFileSizePerWO) {
        repair.fileUpdate = null;
        // this.fileInputUpdate.nativeElement.value = '';
        repair.fileUpdateMessage = "Exceeded 9M.";
        if (this.isTenantGroup) {
          repair.updatedWorkOrderNeedSave = false;
        }
      }
    }
  }

  onFileSelected(event: any) {
    this.message = '';
    const file: File[] = event.target.files;

    this.file = [];
    if (file) {
      this.file = Array.from(file);
    }

    if (this.file && this.file.length > 0) {
      this.totalSize = this.file.reduce((partial: any, f: any) => partial + f.size, 0)

      if (this.totalSize > this.totalAllowedFileSizePerWO) {
        this.file = null;
        this.fileInput.nativeElement.value = '';
        this.message = "Total size exceeded 9M. Please upload smaller size."
      }
    }
  }

  getS3FilePaths(files: any, address: any = null) {
    // let updatedNames: string[] = [];
    let updatedFileName: string[] = [];

    for (const file of files) {
      let fileName = file.name;
      let fileExt = '';

      let splitFileName = fileName.split('.');
      if (splitFileName.length <= 2) {
        fileName = splitFileName[0].split(' ').join('_');
      } else {
        fileName = splitFileName.slice(0, splitFileName.length - 2).join('_');
      }
      fileExt = splitFileName[splitFileName.length - 1];

      if (address) {
        updatedFileName.push(address + '/' + fileName + `.${fileExt}`);
      } else {
        updatedFileName.push(fileName + `.${fileExt}`);
      }
    }

    return updatedFileName;
  }

  uploadFilesApiCall(data: any) {
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    return this.httpClient.put(this.urlWorkOrderFileUpload, data, { headers: this.httpHeaders, observe: 'response' });
  }

  async uploadFileWithSignedUrl() {
    if (this.file && this.file.length > 0) {
      let allFileSignedUrls: Observable<any>[] = [];
      let updatedAddrFileNames: string[] = [];
      let updatedFileNames: string[] = [];

      updatedFileNames = this.getS3FilePaths(this.file);

      let updatedFileNamesIndex = 0;
      for (const file of this.file) {
        try {
          updatedAddrFileNames.push(this.repairItem.address + '/' + updatedFileNames[updatedFileNamesIndex]);

          const data = {
            fileName: updatedFileNames[updatedFileNamesIndex],
            contentType: file.type,
            address: this.repairItem.address,
            signedUrl: true,
          };
          let signedUrlPromise = this.getUploadUrl(data);

          // Test to get signed URL of S3 video to download
          // const data = {
          //   fileName: updatedFileNames[updatedFileNamesIndex],
          //   contentType: file.type,
          //   address: this.repairItem.address,
          //   signedUrl: true,
          // };
          // let signedUrlPromise = this.getDownloadUrl(data);

          if (signedUrlPromise) {
            allFileSignedUrls.push(signedUrlPromise);
          }

          ++updatedFileNamesIndex;
        }
        catch (e) {
          console.log(e);
        }
      }

      forkJoin(allFileSignedUrls).subscribe({
        next: (responses) => {

          this.updateProgress(65)

          console.log('Got all file signed URL succeeded:', responses);

          // Just 1 file url
          // const uploadUrl = responses[0].body.uploadUrl;
          // this.uploadFileToS3(this.file[0], uploadUrl).subscribe(res => {
          //   console.log(" !!!!! Completed upload using signed URL !!!!!!")
          // })

          let allFileUploads: Observable<any>[] = [];

          responses.forEach((res: any, index: number) => {
            const uploadUrl = res.body.uploadUrl;
            let fileUploadPromise = this.uploadFileToS3(updatedFileNames[index], this.file[index], uploadUrl)

            if (fileUploadPromise) {
              allFileUploads.push(fileUploadPromise);
            }
          })

          forkJoin(allFileUploads).subscribe({
            next: (responses) => {

              this.updateProgress(75)

              console.log('All file upload calls succeeded:', responses);

              if (this.tenantInfo) {
                this.tenantInfo.phoneToShow = this.appService.showPhoneNumber(this.tenantInfo.phone);
              }

              if (!this.repairItem.workOrderId) {
                this.repairItem.workOrderId = this.workOrderCounts.workOrderTotal;
              }

              // Send email with files as attachments
              setTimeout(() => {

                let repairItem = JSON.parse(JSON.stringify(this.repairItem))
                repairItem.address = this.getPlainAddress(repairItem.address);

                const workOrderData = {
                  repairItem: repairItem,
                  // address: this.tenantInfo.address, // not needed since fileName has full path
                  fileName: updatedAddrFileNames,
                  propertyManagerContact: {
                    email: this.environment.clientEmail,
                    phone: this.environment.clientPhone,
                  },
                  sendTo: 'PropertyManager',
                  tenant: this.tenantInfo,
                }

                this.sendMail(workOrderData).then(
                  (data: any) => {
                    console.log(data);
                    this.updateProgress(95)
                    this.closeProgressBar();
                    this.workOrderConfirmationModal();
                    this.resetRepairItemUserInputs();
                  }, (error: any) => {
                    console.log("Error in sending mail using signed URL, error: " + JSON.stringify(error))
                  }
                )
              }, 8000)

            },
            error: (error) => {
              console.error('Error in file upload calls:', error);
            }
          })

        },
        error: (error) => {
          console.error('Error in getting signed url calls:', error);
        }
      });
    }
  }



  async uploadFile() {
    if (this.file && this.file.length > 0) {
      // let renterProperty: any = this.savedProperties.find((p: any) => p.renter);
      // let renterProperty: any = this.savedProperties[0];  // test TODO: need to fix

      let allFileUploads: Observable<any>[] = [];
      let updatedAddrFileNames: string[] = [];
      let updatedFileNames: string[] = [];

      updatedFileNames = this.getS3FilePaths(this.file);

      let updatedFileNamesIndex = 0;
      for (const file of this.file) {
        try {

          // let fileName = file.name;
          // let fileExt = '';

          // let splitFileName = fileName.split('.');
          // if (splitFileName.length <= 2) {
          //   fileName = splitFileName[0].split(' ').join('_');
          // } else {
          //   fileName = splitFileName.slice(0, splitFileName.length - 2).join('_');
          // }
          // fileExt = splitFileName[splitFileName.length - 1];

          // let updatedFileName = fileName + `.${fileExt}`;
          // updatedNames.push(this.tenantInfo.address + '/' + updatedFileName);


          updatedAddrFileNames.push(this.repairItem.address + '/' + updatedFileNames[updatedFileNamesIndex]);

          const base64String = await this.appService.encodeFileToBase64(file);
          const data = {
            fileName: updatedFileNames[updatedFileNamesIndex],
            fileContent: base64String.split(',')[1], // Remove the data URL prefix
            contentType: file.type,
            address: this.repairItem.address,
          };

          let fileUploadPromise = this.uploadFilesApiCall(data);

          if (fileUploadPromise) {
            allFileUploads.push(fileUploadPromise);
          }

          ++updatedFileNamesIndex;
        }
        catch (e) {
          console.log(e);
        }
      }

      forkJoin(allFileUploads).subscribe({
        next: (responses) => {

          this.updateProgress(65)

          console.log('All file upload calls succeeded:', responses);

          if (this.tenantInfo) {
            this.tenantInfo.phoneToShow = this.appService.showPhoneNumber(this.tenantInfo.phone);
          }

          if (!this.repairItem.workOrderId) {
            this.repairItem.workOrderId = this.workOrderCounts.workOrderTotal;
          }

          // Send email with files as attachments
          setTimeout(() => {

            let repairItem = JSON.parse(JSON.stringify(this.repairItem))
            repairItem.address = this.getPlainAddress(repairItem.address);

            const workOrderData = {
              repairItem: repairItem,
              // address: this.tenantInfo.address, // not needed since fileName has full path
              fileName: updatedAddrFileNames,
              propertyManagerContact: {
                email: this.environment.clientEmail,
                phone: this.environment.clientPhone,
              },
              sendTo: 'PropertyManager',
              tenant: this.tenantInfo,
            }

            this.sendMail(workOrderData).then(
              (data: any) => {
                console.log(data);
                this.updateProgress(95)
                this.closeProgressBar();
                this.workOrderConfirmationModal();
                this.resetRepairItemUserInputs();
              }, (error: any) => {
                console.log("Error in sending mail NOT with signed URL, error: " + JSON.stringify(error))
              }
            )
          }, 8000)
        },
        error: (error) => {
          console.error('Error in file upload calls:', error);
        }
      });
    }
  }

  sendMail(workOrderData: any) {
    let body = { workOrderData: workOrderData };
    let api = this.environment.rootUrl + 'prod/emailWorkOrder';
    return new Promise((resolve, reject) => {
      this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      this.httpClient.post(api, body, { headers: this.httpHeaders, observe: 'response' })
        .subscribe(
          (data: any) => {
            console.log('Successfully sent email')
            this.updateProgress(95)
            this.closeProgressBar();
            this.workOrderConfirmationModal();
            resolve(data);
          }, (error: any) => {
            console.log('Error in sending email: ' + JSON.stringify(error))
            this.updateProgress(95)
            this.closeProgressBar();
            this.workOrderConfirmationModal();
            reject(error);
          }
        )
    })
  }


  getWorkOrderByStatusAPICall(req: any) {
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    return this.httpClient.post(this.urlWorkOrder, req, { headers: this.httpHeaders, observe: 'response' })
  }

  getWorkOrderByStatus(status: any, address: any = null) {

    let getWOAllPromise: Observable<any>[] = [];

    let req: any;
    let getWOPromise;

    status.forEach((s: any) => {
      req = {
        woStatus: s,
      }

      if (address) {
        req['address'] = address;
      }

      getWOPromise = this.getWorkOrderByStatusAPICall(req);

      if (getWOPromise) {
        getWOAllPromise.push(getWOPromise);
      }
    })

    forkJoin(getWOAllPromise).subscribe({
      next: (responses) => {
        console.log('Success in getting WO :', responses);

        this.sortedRepairs = [];
        this.filteredRepairs = [];
        responses.forEach((r: any) => {
          this.sortedRepairs = [...this.sortedRepairs, ...r.body];
          this.filteredRepairs = [...this.filteredRepairs, ...r.body];
        })

        // this.sortedRepairs = data.body;

        this.sortedRepairs.forEach((r: any, i: number) => {
          this.tenantNotes[i] = r.repairNotes;
          this.propertyManagerNotes[i] = r.propertyManagerNotes;
          this.selectedVendor[i] = r.vendor;
          this.repairCost[i] = r.repairCost;
          this.woStatus[i] = r.woStatus;
          this.repairedDate[i] = r.repairedDate;
          this.filePaths[i] = r.filePaths;
          this.updatedWorkOrderNeedSave[i] = false;
        })

        // new approach
        this.filteredRepairs.forEach((r: any) => {
          r.updatedWorkOrderNeedSave = false;
        })

        this.applyFilter();

      },
      error: (error) => {
        console.error('Error in get WO:', error);
      }
    });


    // let req = {
    //   woStatus: status,
    // }
    // this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    // this.httpClient.post(this.urlWorkOrder, req, { headers: this.httpHeaders, observe: 'response' }).subscribe(
    //   (data: any) => {
    //     console.log("successed");

    //     this.sortedRepairs = data.body;

    //     this.sortedRepairs.forEach((r: any, i: number) => {
    //       this.propertyManagerNotes[i] = r.propertyManagerNotes;
    //     })
    //   },
    //   (error) => {
    //     console.log('error' + error);
    //   }
    // );
  }

  compareVendors(vendor1: any, vendor2: any): boolean {
    return vendor1 && vendor2 ? vendor1.name === vendor2.name : vendor1 === vendor2;
  }

  removeFromWorkOrderTable(item: any) {
    let workOrderTableUrl = this.urlWorkOrder;

    let body = {
      workOrder: item
    }

    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    this.httpClient.delete(workOrderTableUrl, { headers: this.httpHeaders, observe: 'response', body: body, }).subscribe(
      (data: any) => {
        console.log(data)
      }
    )
  }

  getDisplayAddress(address: string) {
    let addr = address.split('-')[0];
    let addrSplitted = addr.split('__');
    return addrSplitted[0] + '\n' + addrSplitted[1];
  }

  getPlainAddress(address: string) {
    let addr = address.split('-')[0];
    let addrSplitted = addr.split('__');
    return addrSplitted[0] + '_' + addrSplitted[1];
  }

  getDownloadUrl(data: any): Observable<any> {
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    return this.httpClient.post(this.urlWorkOrderFileUpload, data, { headers: this.httpHeaders, observe: 'response' });
  }

  // Step 1: Get Pre-Signed URL from Lambda
  getUploadUrl(data: any): Observable<any> {
    this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    return this.httpClient.put(this.urlWorkOrderFileUpload, data, { headers: this.httpHeaders, observe: 'response' });
  }

  // Step 2: Upload file to S3 using the pre-signed URL
  uploadFileToS3(updatedFileName: any, file: File, signedUrl: string): Observable<any> {
    // this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
    // this.httpHeaders = this.httpHeaders.set('Content-Type', file.type);

    const headers = new HttpHeaders({
      'Content-Type': file.type  // Ensure the content type matches the file type
      // 'Content-Type': 'binary/octet-stream'  // Ensure the content type matches the file type
    });

    return this.httpClient.put(signedUrl, file, {
      // headers: {
      //   'Content-Type': file.type
      // },
      // reportProgress: true,
      // observe: 'events',
      // headers: this.httpHeaders,
      headers,
      // observe: 'response'
    });
  }

  updateConsent(event: any) {
    if (event && event.target) {
      this.userConsented = event.target.checked;
    }
  }

  disableViewFileUpload(woIndex: number) {
    let filePaths = this.filePaths[woIndex];
    if (filePaths) {
      return false;
    } else {
      return true;
    }
  }

  disableViewFileUploadFiltered(repair: any) {
    let filePaths = repair.filePaths;
    if (filePaths) {
      return false;
    } else {
      return true;
    }
  }

  // viewFileUpload(woIndex: number) {
  viewFileUploadFiltered(repair: any) {
    let allFileSignedUrls: Observable<any>[] = [];

    let filePaths = repair.filePaths;

    if (filePaths) {
      filePaths.forEach((filePath: any) => {
        const data = {
          fileName: filePath.path,
          // contentType: file.type,  // don't need for get(post)
          // address: this.repairItem.address, // lambda if no address, use fileName as full path to s3
          signedUrl: true,
        };

        let signedUrlPromise = this.getDownloadUrl(data);

        if (signedUrlPromise) {
          allFileSignedUrls.push(signedUrlPromise);
        }
      })

      this.filePathsSignedURLPerWO = [];

      forkJoin(allFileSignedUrls).subscribe({
        next: (responses) => {
          responses.forEach((signedUrl: any) => {
            this.filePathsSignedURLPerWO.push(signedUrl['body']);
            console.log(`signedUrl['body']: ${signedUrl['body']}`)
          })

          const dialogRef = this.dialog.open(WorkOrderFilesViewerComponent, {
            width: this.appService.isMobile ? '100% !important' : '65%',
            data: {
              filePathsSignedURLPerWO: this.filePathsSignedURLPerWO,
              filePaths: filePaths,
            },
            panelClass: 'view-wo-files-dialog-container',
          });
        },
        error: (error) => {
          console.error('Error in getting signed url calls:', error);
        }
      });
    }
  }

  updatedWorkOrder(event: any, index: number) {
    this.updatedWorkOrderNeedSave[index] = true;
  }

  updatedWorkOrderFiltered(event: any, repair: any) {
    repair.updatedWorkOrderNeedSave = true;
  }

  updateWOSelection(event: any, type: string, repair: any) {
    if (event && event.value) {
      repair[type] = event.value;
    }
  }

  toggleStatus(type: string) {
    if (type === 'Open') {
      this.showOpen = !this.showOpen;
      console.log('this.showOpen: ' + this.showOpen)
    }

    if (type === 'Assigned') {
      this.showAssigned = !this.showAssigned;
    }

    if (type === 'Scheduled') {
      this.showScheduled = !this.showScheduled;
    }

    if (type === 'Completed') {
      this.showCompleted = !this.showCompleted;
    }
  }
}
