<div class="accordion mt-3" id="workOrderComponentId">
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingOne">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="false" aria-controls="collapseOne">
                Create Work Order
            </button>
        </h2>
        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne"
            data-bs-parent="#accordionExample">
            <div class="accordion-body">
                <div class="item-wrapper wide-section-wrapper w-100 show-border mt-3">
                    <div class="d-flex w-100 wide-section-wrapper-inner">
                        <p class="ml-2">
                            Please note that this request pertains to non-emergency matters. For any emergency
                            situations, such as fire,
                            flooding, or other urgent concerns, we kindly ask that you contact your property management
                            directly via
                            call or text, or dial 911 if necessary. Please avoid submitting multiple orders for the same
                            issue to avoid delays.
                        </p>
                        <mat-form-field class="wide-section-inner mt-2" appearance="fill">
                            <mat-label>Item <span style="color: red">(Required)</span></mat-label>
                            <mat-select [(ngModel)]="repairItem.repairItem"
                                (selectionChange)="onSelectItem(); setPropertyManagerPredefinedNotes(repairItem)">
                                <mat-option value="refrigerator">Refrigerator</mat-option>
                                <mat-option value="range">Range</mat-option>
                                <mat-option value="range hood">Range Hood</mat-option>
                                <mat-option value="dish washer">Dish Washer</mat-option>
                                <mat-option value="oven">Oven</mat-option>
                                <mat-option value="hvac">HVAC</mat-option>
                                <mat-option value="bathroom">Bathroom</mat-option>
                                <mat-option value="garage">Garage</mat-option>
                                <mat-option value="fire place">Fire Place</mat-option>
                                <mat-option value="other">Other</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <mat-form-field *ngIf="isLandlordGroup" class="mt-2 w-100" appearance="fill">
                            <mat-label>Repaired Date</mat-label>
                            <input matInput [matDatepicker]="repairPicker" [(ngModel)]="repairItem.repairedDate">
                            <mat-hint>MM/DD/YYYY</mat-hint>
                            <mat-datepicker-toggle matIconSuffix [for]="repairPicker"></mat-datepicker-toggle>
                            <mat-datepicker #repairPicker></mat-datepicker>
                        </mat-form-field>

                        <mat-form-field *ngIf="isLandlordGroup" class="wide-section-inner mt-2" appearance="fill">
                            <mat-label>Address <span style="color: red">(Required)</span></mat-label>
                            <mat-select [(ngModel)]="repairItem.address" (selectionChange)="onSelectRepairAddress()">
                                <mat-option *ngFor=" let property of properties;" [value]="property.address">{{
                                    property.address
                                    }}</mat-option>
                            </mat-select>
                        </mat-form-field>

                        <div *ngIf="isLandlordGroup" class="d-flex flex-column wide-section-inner">
                            <mat-form-field class="mt-2 full-height" appearance="fill">
                                <mat-label>{{ "Cost $" | translate }} </mat-label>
                                <input matInput type="number" placeholder="" [(ngModel)]="repairItem.repairCost"
                                    (input)="userInput('repairCost')" maxLength="{{ max_char_repairCost }}" min="0"
                                    max="99999">
                                <mat-hint>{{ "Cost $" | translate }}</mat-hint>
                            </mat-form-field>
                            <div *ngIf="reached_max_char_repairCost" class="max-char-warning-stacked">Maximum number
                                reached.</div>
                        </div>

                        <div *ngIf="isTenantGroup" class="d-flex flex-column wide-section-inner">
                            <mat-form-field class="mt-2" appearance="fill">
                                <mat-label>{{ "Repair Notes: " | translate }} <span style="color: red">{{ repairNoteHint
                                        }} (Required)</span></mat-label>
                                <textarea matInput placeholder="" [(ngModel)]="repairItem.repairNotes" rows="5"
                                    (input)="userInput('repairNotes')"
                                    maxLength="{{ max_char_repairNotes }}"></textarea>
                                <mat-hint>{{ "Repair Notes" | translate }}</mat-hint>
                            </mat-form-field>
                            <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number
                                reached.</div>
                        </div>

                        <div *ngIf="isLandlordGroup" class="d-flex flex-column wide-section-inner">
                            <mat-form-field class="mt-2" appearance="fill">
                                <mat-label>{{ "Property Manager Notes: " | translate }} <span>{{ repairNoteHint
                                        }} </span></mat-label>
                                <textarea matInput placeholder="" [(ngModel)]="repairItem.propertyManagerNotes"
                                    (input)="userInput('repairNotes')"
                                    maxLength="{{ max_char_repairNotes }}"></textarea>
                                <mat-hint>{{ "Property Manager Notes" | translate }}</mat-hint>
                            </mat-form-field>
                            <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number
                                reached.</div>
                        </div>

                        <div class="d-flex wide-section-inner align-items-center">
                            <div class="d-flex flex-column">
                                <div class="d-flex file-selection-wrapper">
                                    <input type="file" #fileInput (change)="onFileSelected($event)" multiple />
                                    <span *ngIf="isTenantGroup" class="mt-1" style="color: red">(Required)</span>
                                </div>
                                <div>Please upload up to 9MB of image/video. Additional image/video could be
                                    added after Work Order is submitted.</div>
                                <div style="color: red">{{message}}</div>
                            </div>
                            <!-- <span *ngIf="isTenantGroup" class="mt-1"
                                style="color: red; position: absolute">(Required)</span> -->
                        </div>

                        <div *ngIf="isTenantGroup" class="tenant-info ml-1 mt-3">
                            <div>Contact:&nbsp;</div>
                            <div *ngIf="tenantInfo?.name">
                                <span><fa-icon [icon]="faHomeUser"></fa-icon></span>
                                <span class="ml-2 mr-2">{{ tenantInfo?.name }}</span>
                            </div>

                            <div *ngIf="tenantInfo?.phone" class="wo-contact-margin">
                                <span><fa-icon [icon]="faPhone"></fa-icon></span>
                                <span class="ml-2 mr-1">{{ appService.showPhoneNumber(tenantInfo?.phone) }}</span>
                            </div>

                            <div *ngIf="tenantInfo?.address" class="wo-contact-margin">
                                <span><fa-icon [icon]="faMapLocationDot"></fa-icon></span>
                                <span class="ml-2 mr-1">{{ getDisplayAddress(tenantInfo?.address) }}</span>
                            </div>
                        </div>

                        <div *ngIf="isTenantGroup" class="d-flex consent-wrapper mt-3 align-items-start">
                            <input type="checkbox" (change)="updateConsent($event)" [checked]="userConsented"
                                class="mt-1" />
                            <p class="ml-2">
                                I agree by providing {{environment.clientName}} your contact information, you
                                acknowledge and
                                agree to our
                                <a [routerLink]="'/privacy-policy'">Privacy Policy</a>,
                                authorize {{environment.clientName}} to share your contact information with the vendor,
                                and
                                consent to the vendor contacting you directly regarding this work order. This
                                consent isn't necessary for purchasing any services or products.
                            </p>
                        </div>

                        <button mat-flat-button type="submit"
                            class="d-flex justify-content-center button-default text-uppercase mt-1"
                            (click)="submitWorkOrder()" [disabled]="!workOrderValid()">{{ "Submit" }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="isLandlordGroup" class="accordion" id="accordionExampleTwo">
    <div class="accordion-item">
        <h2 class="accordion-header" id="headingTwo">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo"
                aria-expanded="true" aria-controls="collapseTwo">
                Filter Work Order
            </button>
        </h2>
        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
            data-bs-parent="#accordionExample">
            <div class="accordion-wrapper d-flex">
                <div>
                    <div>Status</div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="statusOpen" checked
                            (change)="toggleStatus('Open')">
                        <label class="form-check-label" for="statusOpen">Open</label>
                    </div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="statusAssigned" checked
                            (change)="toggleStatus('Assigned')">
                        <label class="form-check-label" for="statusAssigned">Assigned</label>
                    </div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="statusScheduled" checked
                            (change)="toggleStatus('Scheduled')">
                        <label class="form-check-label" for="statusScheduled">Scheduled</label>
                    </div>
                    <div class="form-check form-switch">
                        <input class="form-check-input" type="checkbox" id="statusCompleted"
                            (change)="toggleStatus('Completed')">
                        <label class="form-check-label" for="statusCompleted">Completed<span>&nbsp;(Select
                                Address)</span></label>
                    </div>
                </div>

                <div>
                    <div>Address</div>
                    <mat-form-field class="wide-section-inner mt-2" appearance="fill">
                        <mat-label>Address</mat-label>
                        <mat-select [(ngModel)]="showAddress" (selectionChange)="onSelectFilterAddress()">
                            <mat-option [value]=""></mat-option>
                            <mat-option *ngFor=" let property of properties;" [value]="property.address">{{
                                property.address
                                }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

            </div>

            <div class="d-flex justify-content-center mb-1">
                <button mat-flat-button type="submit"
                    class="d-flex justify-content-center button-default text-uppercase" (click)="applyFilterMain()"
                    [disabled]="!filterValid()">Apply</button>
            </div>
        </div>
    </div>
</div>

<!-- <div class="item-wrapper wide-section-wrapper w-100 show-border mt-3">
    <div class="d-flex w-100 wide-section-wrapper-inner">
        <p class="ml-2">
            Please note that this request pertains to non-emergency matters. For any emergency situations, such as fire,
            flooding, or other urgent concerns, we kindly ask that you contact your property management directly via
            call or text, or dial 911 if necessary.
        </p>
        <mat-form-field class="wide-section-inner mt-2" appearance="fill">
            <mat-label>Item <span style="color: red">(Required)</span></mat-label>
            <mat-select [(ngModel)]="repairItem.repairItem">
                <mat-option value="refrigerator">Refrigerator</mat-option>
                <mat-option value="range">Range</mat-option>
                <mat-option value="range hood">Range Hood</mat-option>
                <mat-option value="dish washer">Dish Washer</mat-option>
                <mat-option value="oven">Oven</mat-option>
                <mat-option value="fire place">Fire Place</mat-option>
                <mat-option value="hvac">HVAC</mat-option>
                <mat-option value="toilet">Toilet</mat-option>
                <mat-option value="sink">Sink</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field *ngIf="isLandlordGroup" class="mt-2 w-100" appearance="fill">
            <mat-label>Repaired Date</mat-label>
            <input matInput [matDatepicker]="repairPicker" [(ngModel)]="repairItem.repairedDate">
            <mat-hint>MM/DD/YYYY</mat-hint>
            <mat-datepicker-toggle matIconSuffix [for]="repairPicker"></mat-datepicker-toggle>
            <mat-datepicker #repairPicker></mat-datepicker>
        </mat-form-field>

        <mat-form-field *ngIf="isLandlordGroup" class="wide-section-inner mt-2" appearance="fill">
            <mat-label>Address <span style="color: red">(Required)</span></mat-label>
            <mat-select [(ngModel)]="repairItem.address" (selectionChange)="onSelectRepairAddress()">
                <mat-option *ngFor=" let property of properties;" [value]="property.address">{{ property.address
                    }}</mat-option>
            </mat-select>
        </mat-form-field>

        <div *ngIf="isLandlordGroup" class="d-flex flex-column wide-section-inner">
            <mat-form-field class="mt-2 full-height" appearance="fill">
                <mat-label>{{ "Cost $" | translate }} </mat-label>
                <input matInput type="number" placeholder="" [(ngModel)]="repairItem.repairCost"
                    (input)="userInput('repairCost')" maxLength="{{ max_char_repairCost }}" min="0" max="99999">
                <mat-hint>{{ "Cost $" | translate }}</mat-hint>
            </mat-form-field>
            <div *ngIf="reached_max_char_repairCost" class="max-char-warning-stacked">Maximum number
                reached.</div>
        </div>

        <div *ngIf="isTenantGroup" class="d-flex flex-column wide-section-inner">
            <mat-form-field class="mt-2" appearance="fill">
                <mat-label>{{ "Repair Notes" | translate }} <span style="color: red">(Required)</span></mat-label>
                <textarea matInput placeholder="" [(ngModel)]="repairItem.repairNotes" rows="5"
                    (input)="userInput('repairNotes')" maxLength="{{ max_char_repairNotes }}"></textarea>
                <mat-hint>{{ "Repair Notes" | translate }}</mat-hint>
            </mat-form-field>
            <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number
                reached.</div>
        </div>

        <div *ngIf="isLandlordGroup" class="d-flex flex-column wide-section-inner">
            <mat-form-field class="mt-2" appearance="fill">
                <mat-label>{{ "Property Manager Notes" | translate }} </mat-label>
                <textarea matInput placeholder="" [(ngModel)]="repairItem.propertyManagerNotes"
                    (input)="userInput('repairNotes')" maxLength="{{ max_char_repairNotes }}"></textarea>
                <mat-hint>{{ "Property Manager Notes" | translate }}</mat-hint>
            </mat-form-field>
            <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number
                reached.</div>
        </div>

        <div class="d-flex wide-section-inner align-items-center">
            <div class="d-flex flex-column">
                <input type="file" #fileInput (change)="onFileSelected($event)" multiple />
                <div style="color: red">{{message}}</div>
            </div>
            <span *ngIf="isTenantGroup" class="mt-1" style="color: red">(Required)</span>
        </div>

        <div *ngIf="isTenantGroup" class="tenant-info ml-1 mt-3">
            <div>Contact:&nbsp;</div>
            <div *ngIf="tenantInfo?.name">
                <span><fa-icon [icon]="faHomeUser"></fa-icon></span>
                <span class="ml-2 mr-2">{{ tenantInfo?.name }}</span>
            </div>

            <div *ngIf="tenantInfo?.phone" class="wo-contact-margin">
                <span><fa-icon [icon]="faPhone"></fa-icon></span>
                <span class="ml-2 mr-1">{{ appService.showPhoneNumber(tenantInfo?.phone) }}</span>
            </div>

            <div *ngIf="tenantInfo?.address" class="wo-contact-margin">
                <span><fa-icon [icon]="faMapLocationDot"></fa-icon></span>
                <span class="ml-2 mr-1">{{ getDisplayAddress(tenantInfo?.address) }}</span>
            </div>
        </div>

        <div *ngIf="isTenantGroup" class="d-flex consent-wrapper mt-3 align-items-start">
            <input type="checkbox" (change)="updateConsent($event)" [checked]="userConsented" class="mt-1" />
            <p class="ml-2">
                I agree by providing {{environment.clientName}} your contact information, you
                acknowledge and
                agree to our
                <a [routerLink]="'/privacy-policy'">Privacy Policy</a>. This consent isn't necessary for
                purchasing
                any services or products. You may opt out at any time.
            </p>
        </div>

        <button mat-flat-button type="submit" class="d-flex justify-content-center button-default text-uppercase"
            (click)="submitWorkOrder()" [disabled]="!workOrderValid()">{{ "Submit" }}</button>
    </div>
</div> -->


<!-- ----- Show submitted work order-------- -->
<table mat-table matSort *ngIf="filteredRepairs && filteredRepairs.length > 0" (matSortChange)="sortData($event)"
    class="mt-3 repair-table-wrapper">
    <tr>
        <th mat-sort-header="woId" style="width: 8%"><span>ID</span></th>
        <th mat-sort-header="status" style="width: 20%;">Status</th>
        <th *ngIf="isLandlordGroup" mat-sort-header="openedDate" style="width: 15%"><span>Date Opened</span></th>
        <th *ngIf="isLandlordGroup" mat-sort-header="repairedDate" style="width: 22%">Repaired Date</th>
        <th mat-sort-header="item" style="width: 18%">Item</th>
        <th *ngIf="isLandlordGroup" mat-sort-header="address" style="width: 35%">Address</th>
        <th *ngIf="isLandlordGroup" mat-sort-header="cost" style="width: 17%">Cost($)</th>
        <th mat-sort-header="files" style="width: 21%">Files</th>
        <th mat-sort-header="notes" style="width: 35%">Tenant Notes</th>
        <th *ngIf="isLandlordGroup" mat-sort-header="propertyManagernotes" style="width: 55%">Manager Notes</th>
        <th *ngIf="isLandlordGroup" mat-sort-header="vendor" style="width: 20%">Send To Vendor</th>
    </tr>

    <!-- @for (repair of sortedRepairs; track repair) { -->
    <tr *ngFor="let repair of filteredRepairs; let i = index" class="repair-table-row">
        <td><span style="margin-left: 10px">{{repair.workOrderId}}</span></td>

        <!-- <td>
            <mat-form-field class="wide-section-inner mt-2" style="width:90%" appearance="fill">
                <mat-select [(ngModel)]="woStatus[i]" [disabled]="isTenantGroup"
                    (selectionChange)="updatedWorkOrder($event, i)">
                    <mat-option *ngFor=" let status of woStatuses;" [value]="status">{{ status }}</mat-option>
                </mat-select>
            </mat-form-field>
        </td> -->

        <td>
            <mat-form-field class="wide-section-inner mt-2" style="width:90%" appearance="fill">
                <mat-select [(ngModel)]="repair.woStatus" [disabled]="isTenantGroup"
                    (selectionChange)="updateWOSelection($event, 'woStatus', repair); updatedWorkOrderFiltered($event, repair)">
                    <mat-option *ngFor=" let status of woStatuses;" [value]="status">{{ status }}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>

        <td *ngIf="isLandlordGroup">{{showOpenedDate(repair)}}</td>
        <!-- <td style="width: 10%">{{showRepairedDate(repair)}}</td> -->

        <!-- <td *ngIf="isLandlordGroup">
            <mat-form-field class="w-100 mt-2" appearance="fill">
                <input matInput [matDatepicker]="repairPickerUpdate" [(ngModel)]="repairedDate[i]"
                    (dateChange)="updatedWorkOrder($event, i)">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="repairPickerUpdate"></mat-datepicker-toggle>
                <mat-datepicker #repairPickerUpdate></mat-datepicker>
            </mat-form-field>
        </td> -->
        <td *ngIf="isLandlordGroup">
            <mat-form-field class="w-100 mt-2" appearance="fill">
                <input matInput [matDatepicker]="repairPickerUpdate" [(ngModel)]="repair.repairedDate"
                    (dateChange)="updatedWorkOrderFiltered($event, repair)">
                <mat-hint>MM/DD/YYYY</mat-hint>
                <mat-datepicker-toggle matIconSuffix [for]="repairPickerUpdate"></mat-datepicker-toggle>
                <mat-datepicker #repairPickerUpdate></mat-datepicker>
            </mat-form-field>
        </td>


        <td class="d-flex ml-1" style="margin-top: 10%">{{repair?.repairItem}}</td>

        <td *ngIf="isLandlordGroup">{{ getDisplayAddress(repair?.address) }}</td>

        <!-- <td *ngIf="isLandlordGroup">
            <mat-form-field class="w-100 wide-section-inner mt-2" appearance="fill">
                <input matInput type="number" placeholder="" [(ngModel)]="repairCost[i]"
                    (input)="userInput('repairCost'); updatedWorkOrder($event, i)" maxLength="{{ max_char_repairCost }}"
                    min="0" max="99999">
            </mat-form-field>
            <div *ngIf="reached_max_char_repairCost" class="max-char-warning-stacked">Maximum number
                reached.</div>
        </td> -->
        <td *ngIf="isLandlordGroup">
            <mat-form-field class="w-100 wide-section-inner mt-2" appearance="fill">
                <input matInput type="number" placeholder="" [(ngModel)]="repair.repairCost"
                    (input)="userInput('repairCost'); updatedWorkOrderFiltered($event, repair)"
                    maxLength="{{ max_char_repairCost }}" min="0" max="99999">
            </mat-form-field>
            <div *ngIf="reached_max_char_repairCost" class="max-char-warning-stacked">Maximum number
                reached.</div>
        </td>


        <!-- <td class="">
            <div class="d-flex">
                <div [ngClass]="{'disabled-icon': disableViewFileUpload(i)}">
                    <fa-icon (click)="viewFileUpload(i)" [icon]="faFileImage" class="delete-repair-plus btn btn-success"
                        style="margin-top: 0px"></fa-icon>
                </div>
                <div>
                    <input type="file" #fileInputUpdate (change)="onFileUpdate($event)" style="display: none;"
                        multiple />
                    <fa-icon [icon]="faFileCirclePlus" class="delete-repair-plus btn btn-success"
                        (click)="triggerFileUpdate(i)" style="margin-top: 0px; padding-right: 6px"></fa-icon>
                    <div style="color: red">{{fileUpdateMessage[i]}}</div>
                </div>
            </div>
        </td> -->

        <td class="">
            <div class="d-flex">
                <div [ngClass]="{'disabled-icon': disableViewFileUploadFiltered(repair)}">
                    <fa-icon (click)="viewFileUploadFiltered(repair)" [icon]="faFileImage"
                        class="delete-repair-plus btn btn-success" style="margin-top: 0px"></fa-icon>
                </div>
                <div>
                    <input type="file" #fileInputUpdate (change)="onFileUpdateFiltered($event, i, repair)"
                        style="display: none;" multiple />
                    <fa-icon [icon]="faFileCirclePlus" class="delete-repair-plus btn btn-success"
                        (click)="triggerFileUpdate(i)" style="margin-top: 0px; padding-right: 6px"></fa-icon>
                    <div style="color: red">{{repair.fileUpdateMessage}}</div>
                </div>
            </div>
        </td>

        <!-- <td>
            <mat-form-field class="mt-2" style="width: 92%" appearance="fill">
                <textarea matInput placeholder="" [(ngModel)]="tenantNotes[i]" rows="1"
                    (input)="userInput('repairNotes'); updatedWorkOrder($event, i)"
                    maxLength="{{ max_char_repairNotes }}" [disabled]="disableRepairNotes"></textarea>
                <mat-hint>{{ "Tenant Notes" | translate }}</mat-hint>
            </mat-form-field>
            <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number reached.</div>
        </td> -->

        <td>
            <mat-form-field class="mt-2" style="width: 92%" appearance="fill">
                <textarea matInput placeholder="" [(ngModel)]="repair.repairNotes" rows="1"
                    (input)="userInput('repairNotes'); updatedWorkOrderFiltered($event, repair)"
                    maxLength="{{ max_char_repairNotes }}" [disabled]="disableRepairNotes"></textarea>
                <mat-hint>{{ "Tenant Notes" | translate }}</mat-hint>
            </mat-form-field>
            <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number reached.</div>
        </td>


        <!-- <td *ngIf="isLandlordGroup">
            <div class="d-flex">
                <div>
                    <mat-form-field class="mt-2" style="width: 92%" appearance="fill">
                        <textarea matInput placeholder="" [(ngModel)]="propertyManagerNotes[i]" rows="1"
                            (input)="userInput('repairNotes'); updatedWorkOrder($event, i)"
                            maxLength="{{ max_char_repairNotes }}"></textarea>
                        <mat-hint>{{ "Property Manager Notes" | translate }}</mat-hint>
                    </mat-form-field>
                    <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number reached.</div>
                </div>
                <fa-icon (click)="noteModal(i)" [icon]="faEdit" style="margin-left: 0px"
                    class="update-notes btn btn-success"></fa-icon>
            </div>
        </td> -->
        <td *ngIf="isLandlordGroup">
            <div class="d-flex">
                <div>
                    <mat-form-field class="mt-2" style="width: 92%" appearance="fill">
                        <textarea matInput placeholder="" [(ngModel)]="repair.propertyManagerNotes" rows="1"
                            (input)="userInput('repairNotes'); updatedWorkOrderFiltered($event, repair)"
                            maxLength="{{ max_char_repairNotes }}"></textarea>
                        <mat-hint>{{ "Property Manager Notes" | translate }}</mat-hint>
                    </mat-form-field>
                    <div *ngIf="reached_max_char_repairNotes" class="max-char-warning">Maximum number reached.</div>
                </div>
                <fa-icon (click)="noteModal(repair)" [icon]="faEdit" style="margin-left: 0px"
                    class="update-notes btn btn-success"></fa-icon>
            </div>
        </td>


        <!-- <td *ngIf="isLandlordGroup">
            <mat-form-field class="w-100 wide-section-inner mt-2" appearance="fill">
                <mat-select [(ngModel)]="selectedVendor[i]" [compareWith]="compareVendors"
                    (selectionChange)="updatedWorkOrder($event, i)">
                    <mat-option value=""></mat-option>
                    <mat-option *ngFor=" let vendor of vendors;" [value]="vendor">{{ vendor.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </td> -->
        <td *ngIf="isLandlordGroup">
            <mat-form-field class="w-100 wide-section-inner mt-2" appearance="fill" style="padding-right: 6px">
                <mat-select [(ngModel)]="repair.vendor" [compareWith]="compareVendors"
                    (selectionChange)="updatedWorkOrderFiltered($event, repair)">
                    <mat-option [value]=""></mat-option>
                    <mat-option *ngFor=" let vendor of vendors;" [value]="vendor">{{ vendor.name }}</mat-option>
                </mat-select>
            </mat-form-field>
        </td>


        <div class="d-flex">
            <!-- <fa-icon (click)="saveWorkOrder(i, repair)" [icon]="faSave" class="delete-repair-plus btn btn-success"
                [ngClass]="{'disabled-icon': !updatedWorkOrderNeedSave[i]}"></fa-icon> -->
            <fa-icon (click)="saveWorkOrder(i, repair)" [icon]="faSave" class="delete-repair-plus btn btn-success"
                [ngClass]="{'disabled-icon': !repair.updatedWorkOrderNeedSave}"></fa-icon>

            <fa-icon *ngIf="isLandlordGroup" (click)="removeRepairItem(i)" [icon]="faTrashCan"
                class="delete-repair-plus ml-2 btn btn-danger"></fa-icon>
        </div>
    </tr>

</table>